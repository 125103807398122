import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Grid, Box } from '@chatterbug/aaron'
export type Props = {
  imgUrl: string
  title: string
  description: string
}

const InfoCardContent: React.FC<Props> = ({ imgUrl, title, description }) => {
  return (
    <Grid gridGap="2x" data-testid="info-card" alignItems="center">
      {imgUrl && (
        <Box
          height="184px"
          borderRadius="md"
          backgroundImage={`url(${imgUrl})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          overflow="hidden"
        />
      )}
      <Box as="p" variant="text.h4">
        {title}
      </Box>
      <Box variant="text.paragraph">
        <ReactMarkdown skipHtml>{description}</ReactMarkdown>
      </Box>
    </Grid>
  )
}

export default InfoCardContent
