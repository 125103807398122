export const trackEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string
) => {
  try {
    // @ts-ignore
    window?.dataLayer?.push?.({
      event: 'gtmEvent',
      eventCategory,
      eventAction,
      eventLabel,
    })
  } catch (error) {
    console.error(error)
  }
}

export const trackStreamsWebPromotion = (
  eventAction: string,
  eventLabel: string
) => {
  trackEvent('web-promotion-streams', eventAction, eventLabel)
}

export const trackStreamsGiftVoucherRedemption = (
  eventAction: string,
  eventLabel: string
) => {
  trackEvent('gift-voucher-streams', eventAction, eventLabel)
}

export const trackChatterconfEvent = (
  year: string,
  eventAction: string,
  eventLabel: string
) => {
  trackEvent(`chatterconf-${year}`, eventAction, eventLabel)
}
