import React from 'react'
import FullWidthBackground from 'src/components/FullWidthBackground/FullWidthBackground'
import { Box } from '@chatterbug/aaron'
import stars from './assets/stars.svg'
import curvedBackground from './assets/curved-background.svg'
import illustration from './assets/illustration.svg'
import earth from './assets/earth.svg'

const STARS_SIZE = {
  width: 1480,
  height: 423,
}

const HILL_SIZE = {
  width: 1500,
  height: 390,
}
const ILLUSTRATION_SIZE = {
  width: 1327,
  height: 187,
}

const POSITION_ABSOLUTE = {
  position: 'absolute',
  left: 0,
  width: '100%',
}

const POSITION_ABSOLUTE_FULL = {
  position: 'absolute',
  left: 0,
  width: '100%',
  height: 'auto',
  bottom: 0,
}

const EARTH_POSITION = {
  position: 'absolute',
  width: 93,
  right: '10%',
  top: '10%',
}

export type Props = {
  hasInnerContent: boolean
}

const ChatterconfHeroBackground: React.FC<Props> = ({ hasInnerContent }) => {
  return (
    <>
      <Box
        display={{
          _: 'none',
          tablet: 'block',
        }}
      >
        <FullWidthBackground
          image={stars}
          width={STARS_SIZE.width}
          height={STARS_SIZE.height}
          sx={{ ...POSITION_ABSOLUTE, top: '60px' }}
        />
        <FullWidthBackground
          image={curvedBackground}
          width={HILL_SIZE.width}
          height={HILL_SIZE.height}
          sx={{ ...POSITION_ABSOLUTE_FULL, bottom: '-1px' }}
        />
        <FullWidthBackground
          image={illustration}
          width={ILLUSTRATION_SIZE.width}
          height={ILLUSTRATION_SIZE.height}
          sx={{
            ...POSITION_ABSOLUTE,
            bottom: HILL_SIZE.height - ILLUSTRATION_SIZE.height + 60,
          }}
        />
      </Box>
      <Box
        display={{
          _: 'none',
          tabletWide: 'block',
        }}
      >
        <FullWidthBackground
          image={earth}
          width={93}
          height={92}
          sx={EARTH_POSITION}
        />
      </Box>
      <Box
        display={{
          mobile: 'block',
          tablet: 'none',
        }}
      >
        <FullWidthBackground
          image={curvedBackground}
          width={HILL_SIZE.width - HILL_SIZE.width / 3}
          height={HILL_SIZE.height - HILL_SIZE.height / 3}
          sx={POSITION_ABSOLUTE_FULL}
        />
      </Box>
    </>
  )
}

export default ChatterconfHeroBackground
