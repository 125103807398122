import React from 'react'
import { Box, Grid, Flex, Image } from '@chatterbug/aaron'

export type Props = {
  columnsAbreast: number
  imageUrl?: string
  title?: string
  items: JSX.Element[]
  isBackgroundDark?: boolean
  customWidth?: string
  isAList?: boolean
}

const ChatterconfSectionGrid: React.FC<Props> = ({
  columnsAbreast,
  imageUrl,
  title,
  items,
  isBackgroundDark,
  customWidth,
  isAList,
}) => {
  return (
    <Flex as="section" flexDirection="column" alignItems="center">
      {imageUrl && (
        <Image
          data-testid="section-image"
          width="48px"
          height="48px"
          src={imageUrl}
          alignSelf="center"
          alt=""
        />
      )}
      {title && (
        <Box
          data-testid="section-title"
          mt={imageUrl && '1x'}
          as="h2"
          variant="text.h5"
          sx={{ textTransform: 'uppercase' }}
          textAlign="center"
          color={isBackgroundDark ? 'white100' : 'black19'}
          letterSpacing="0.1rem"
        >
          {title}
        </Box>
      )}
      <Grid
        as={isAList ? 'ul' : 'div'}
        //A ul has padding on the left; we reset it below
        pl="0"
        maxWidth={customWidth}
        mt={title && '4x'}
        gridGap={{ _: '2x', tablet: '2x', desktop: '3x' }}
        data-testid="section-grid"
        gridTemplateColumns={{
          _: '1fr',
          tablet: `repeat(${columnsAbreast}, 1fr)`,
        }}
      >
        {items}
      </Grid>
    </Flex>
  )
}

export default ChatterconfSectionGrid
