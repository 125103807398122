import React from 'react'
import { Flex } from '@chatterbug/aaron'

export type Props = {
  focused?: boolean //TODO; tighten this up like the tutor card
  onClick?: () => void
  className?: string
  isInAList?: boolean
}

const WIDTH = '100%'
const CardRaised: React.FC<Props> = ({
  focused,
  onClick,
  children,
  className,
  isInAList,
}) => {
  return (
    <Flex
      as={isInAList ? 'li' : 'div'}
      data-testid="raised-card"
      className={className || 'raised-card'} //className is for tracking purposes.
      flexDirection="column"
      alignItems="center"
      boxShadow={focused ? 'pressed' : 'overlay'}
      borderStyle="solid"
      borderWidth={2}
      borderColor={focused ? 'yellow50' : 'transparent'}
      bg="white100"
      borderRadius="18px"
      p="3x"
      width={WIDTH}
      onClick={onClick}
      sx={{
        transition: 'border-color 0.3s ease, box-shadow 0.3s ease',
        ...(onClick
          ? {
              cursor: 'pointer',
              ':hover': {
                borderColor: 'yellow50',
              },
            }
          : {}),
      }}
    >
      {children}
    </Flex>
  )
}

export default CardRaised

//A card should render it's children.
