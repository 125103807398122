import React from 'react'
import { Box } from '@chatterbug/aaron'
import ChatterconfSectionTopper from './subcomponents/ChatterconfSectionTopper/ChatterconfSectionTopper'
import PageContainer from 'src/components/PageContainer/PageContainer'
export type Props = {
  isBackgroundDark?: boolean
  top: 'curved' | 'flat'
  id?: string
}

const DARK_BACKGROUND = ['#302551', '#37296F']
//TODO: can you replace these hard coded colours with tokens?
const LIGHT_BACKGROUND = ['#FAFAFA', '#FAFAFA'] //gray99

const ChatterconfSection: React.FC<Props> = ({
  isBackgroundDark,
  top,
  children,
  id,
}) => {
  const darkBackgroundGradient = `linear-gradient(99.84deg, ${DARK_BACKGROUND[0]} 6.64%, ${DARK_BACKGROUND[1]} 92.47%);`
  const lightBackgroundGradient = `linear-gradient(99.84deg, ${LIGHT_BACKGROUND[0]} 6.64%, ${LIGHT_BACKGROUND[1]} 92.47%);`
  const gradientId = isBackgroundDark
    ? `${DARK_BACKGROUND[0] + DARK_BACKGROUND[1]}`
    : `${LIGHT_BACKGROUND[0] + LIGHT_BACKGROUND[1]}`
  return (
    <Box
      id={id}
      backgroundImage={
        top === 'curved' && !isBackgroundDark
          ? darkBackgroundGradient
          : top === 'curved'
          ? lightBackgroundGradient
          : null
      }
    >
      {top === 'curved' && (
        <ChatterconfSectionTopper
          gradientId={gradientId}
          gradientColorOne={
            isBackgroundDark ? DARK_BACKGROUND[0] : LIGHT_BACKGROUND[0]
          }
          gradientColorTwo={
            isBackgroundDark ? DARK_BACKGROUND[1] : LIGHT_BACKGROUND[1]
          }
        />
      )}
      <Box
        mt="-3px"
        pt={top === 'curved' ? '60px' : '0'}
        pb="80px"
        data-testid="section"
        backgroundImage={
          isBackgroundDark
            ? darkBackgroundGradient
            : top === 'curved'
            ? lightBackgroundGradient
            : null
        }
      >
        <PageContainer>{children}</PageContainer>
      </Box>
    </Box>
  )
}

export default ChatterconfSection
