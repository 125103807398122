import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { ChatterconfPagesJson, PageContext } from 'src/types'
import { Box, Grid, Image } from '@chatterbug/aaron'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import Head from 'src/components/Head/Head'
import Layout from 'src/components/Layout/Layout'
import ChatterconfHero from 'src/sections/Chatterconf/ChatterconfHero/ChatterconfHero'
import ChatterconfSection from 'src/sections/Chatterconf/ChatterconfSection/ChatterconfSection'
import ChatterconfSectionGrid from 'src/sections/Chatterconf/ChatterconfSection/subcomponents/ChatterconfSectionGrid/ChatterconfSectionGrid'
import CardRaised from 'src/components/CardRaised/CardRaised'
import breakouts from 'src/sections/Chatterconf/ChatterconfSection/assets/breakouts.svg'
import presentations from 'src/sections/Chatterconf/ChatterconfSection/assets/presentations.svg'
import speakers from 'src/sections/Chatterconf/ChatterconfSection/assets/speakers.svg'
import IndividualBio, {
  IndividualBioCtaWithModal,
} from 'src/components/IndividualBio/IndividualBio'
import Excerpt from 'src/components/Excerpt/Excerpt'
import InfoCardContent from 'src/components/InfoCardContent/InfoCardContent'
import ActionCard from 'src/components/ActionCard/ActionCard'
import { sortDesc } from 'src/lib/utils'
import { trackChatterconfEvent } from 'src/lib/tracking'

type DataProps = {
  allChatterconfPagesJson: ChatterconfPagesJson
}

type ChatterconfPageContext = PageContext & {
  year: string
}

const ChatterconfPage: React.FC<PageProps<
  DataProps,
  ChatterconfPageContext
>> = ({ pageContext, data }) => {
  const pageData = data.allChatterconfPagesJson.nodes[0]

  const handleCTAClick = (eventLabel: string) => {
    trackChatterconfEvent(pageContext.year, 'cta', eventLabel)
  }

  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <Head
        title={`Chatterconf ${pageData.year}`}
        description={pageData.socialDescription}
        socialImage={pageData.socialImage}
        pageContext={pageContext}
        realDomainCanonicalUrl="https://chatterconf.com/2021"
      />
      <ChatterconfHero
        id="schedule"
        logoImage={pageData.logoImage}
        date={pageData.date}
        subtitle={pageData.subtitle}
        ctaLink={pageData.ctaLink}
        ctaLabel={pageData.ctaLabel}
        featuredCompanies={pageData.featuredCompanies}
        canonicalPath={pageContext.canonicalPath}
        showBreakoutsSection={!pageData.roomsSection?.isHidden}
        onCTAClick={handleCTAClick}
      />
      <Box
        sx={{
          position: 'relative',
          marginTop: '-150px',
        }}
      >
        <ChatterconfSection top="flat" id="speakers">
          <Grid gridGap="100px">
            <ChatterconfSectionGrid
              isAList
              imageUrl={speakers}
              columnsAbreast={3}
              items={sortDesc(pageData.speakersSection.list).map(
                (item, index) => (
                  <CardRaised key={`speaker${index}`} isInAList>
                    <IndividualBio
                      imgUrl={item.speakerPhoto}
                      title={item.speakerName}
                      subtitle={item.companyName}
                      description={item.shortBio}
                      ctaSection={
                        <IndividualBioCtaWithModal
                          imgUrl={item.speakerPhoto}
                          ariaLabel={`Open full profile for ${item.speakerName}`}
                          title={item.speakerName}
                          subtitle={item.companyName}
                          description={item.longBio}
                          secondarySection={
                            item.companyBio ? (
                              <Grid
                                pt="5x"
                                borderTopColor="gray80"
                                borderTopWidth="small"
                                borderTopStyle="solid"
                                gridGap="3x"
                                justifyItems="center"
                              >
                                {item.companyLogo && (
                                  <Image
                                    maxWidth="150px"
                                    src={item.companyLogo}
                                    alt={item.companyName}
                                  />
                                )}
                                <Box
                                  as="p"
                                  variant="text.paragraph"
                                  color="gray46"
                                >
                                  {item.companyBio}
                                </Box>
                              </Grid>
                            ) : undefined
                          }
                        />
                      }
                    />
                  </CardRaised>
                )
              )}
              title={pageData.speakersSection.name}
            />
          </Grid>
        </ChatterconfSection>
      </Box>
      <ChatterconfSection top="curved" isBackgroundDark id="presentations">
        <Grid gridGap="100px">
          <Grid
            flexDirection="column"
            justifyItems="center"
            gridGap="6x"
            color="white100"
            textAlign="center"
          >
            <Box as="h3" variant="text.h3" color="white100" maxWidth="650px">
              {pageData.presentationsSection.title}
            </Box>
            <Box
              as="p"
              variant="text.paragraph"
              color="white100"
              maxWidth="650px"
            >
              {pageData.presentationsSection.subtitle}
            </Box>
            <Button
              mt="3x"
              label={pageData.presentationsSection.ctaLabel}
              href={pageData.presentationsSection.ctaLink}
              variant={BUTTON_VARIANTS.primary}
              onClick={() => handleCTAClick('presentations')}
            />
          </Grid>
          <ChatterconfSectionGrid
            customWidth="760px"
            isBackgroundDark
            imageUrl={presentations}
            columnsAbreast={1}
            items={sortDesc(pageData.presentationsSection.list).map(
              (item, index) => (
                <CardRaised key={`speaker${index}`}>
                  <Excerpt
                    imgUrl={item.speakerPhoto}
                    name={item.speakerName}
                    logoUrl={item.companyLogo}
                    logoName={item.companyName}
                    title={item.title}
                    description={item.description}
                  />
                </CardRaised>
              )
            )}
            title={pageData.presentationsSection.name}
          />
        </Grid>
      </ChatterconfSection>
      <ChatterconfSection top="curved" id="breakouts">
        <Grid gridGap="6x">
          {!pageData.roomsSection?.isHidden &&
            pageData.roomsSection?.list?.length > 0 && (
              <ChatterconfSectionGrid
                imageUrl={breakouts}
                columnsAbreast={2}
                items={sortDesc(pageData.roomsSection.list).map(
                  (item, index) => (
                    <CardRaised key={`speaker${index}`}>
                      <InfoCardContent
                        imgUrl={item.image}
                        title={item.title}
                        description={item.description}
                      />
                    </CardRaised>
                  )
                )}
                title={pageData.roomsSection.name}
              />
            )}
          <ActionCard
            message={pageData.bottomSection.label}
            customButton={
              <Button
                height="44px"
                label={pageData.bottomSection.ctaLabel}
                href={pageData.bottomSection.ctaLink}
                variant={BUTTON_VARIANTS.primary}
                onClick={() => handleCTAClick('bottom')}
              />
            }
          />
        </Grid>
      </ChatterconfSection>
    </Layout>
  )
}

export default ChatterconfPage

export const query = graphql`
  query FetchChatterconfData($year: Date) {
    allChatterconfPagesJson(filter: { year: { eq: $year } }, limit: 1) {
      nodes {
        year
        date
        subtitle
        ctaLabel
        ctaLink
        socialDescription
        socialImage
        logoImage
        featuredCompanies {
          companyName
          companyLogo
          orderByDesc
        }
        speakersSection {
          name
          list {
            speakerName
            speakerPhoto
            companyName
            companyBio
            companyUrl
            companyLogo
            shortBio
            longBio
            orderByDesc
          }
        }
        presentationsSection {
          name
          title
          subtitle
          ctaLabel
          ctaLink
          list {
            speakerName
            speakerPhoto
            companyName
            companyUrl
            companyLogo
            title
            description
            orderByDesc
          }
        }
        roomsSection {
          list {
            title
            description
            image
            orderByDesc
          }
          name
          isHidden
        }
        bottomSection {
          label
          ctaLabel
          ctaLink
        }
      }
    }
  }
`
