import React from 'react'
import { Box } from '@chatterbug/aaron'
import PageContainer from 'src/components/PageContainer/PageContainer'
import ChatterconfHeader from 'src/sections/Chatterconf/ChatterconfHeader/ChatterconfHeader'

import ChatterconfHeroContent from 'src/sections/Chatterconf/ChatterconfHeroContent/ChatterconfHeroContent'
import ChatterconfHeroBackground from './subcomponents/ChatterconfHeroBackground/ChatterconfHeroBackground'
import { ChatterconfFeaturedCompany } from 'src/types'

export const SCENE_HEIGHT = 900

export type Props = {
  logoImage?: string
  canonicalPath: string
  date: string
  subtitle: string
  ctaLabel: string
  ctaLink: string
  featuredCompanies: ChatterconfFeaturedCompany[]
  showBreakoutsSection?: boolean
  id?: string
  onCTAClick: (eventLabel: string) => void
}

const ChatterconfHero: React.FC<Props> = ({
  logoImage,
  canonicalPath,
  children,
  date,
  subtitle,
  ctaLabel,
  ctaLink,
  showBreakoutsSection,
  featuredCompanies,
  id,
  onCTAClick,
}) => {
  return (
    <Box
      id={id}
      sx={{
        position: 'relative',
        minHeight: SCENE_HEIGHT,
        maxWidth: '100%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 200,
          backgroundImage:
            'linear-gradient(99.84deg, #302551 6.64%, #37296F 92.47%);',
        }}
      />
      <ChatterconfHeroBackground hasInnerContent={Boolean(children)} />
      <Box position="relative">
        <PageContainer>
          <ChatterconfHeader
            showBreakoutsSection={showBreakoutsSection}
            ctaLink={ctaLink}
            canonicalPath={canonicalPath}
            onCTAClick={onCTAClick}
          />
          <ChatterconfHeroContent
            logoImage={logoImage}
            date={date}
            subtitle={subtitle}
            ctaLink={ctaLink}
            ctaLabel={ctaLabel}
            featuredCompanies={featuredCompanies}
            onCTAClick={onCTAClick}
          />
        </PageContainer>
      </Box>
    </Box>
  )
}

export default ChatterconfHero
