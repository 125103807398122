import React from 'react'

export type Props = {
  gradientColorOne: string
  gradientColorTwo: string
  gradientId: string
}

const ChatterconfSectionTopper: React.FC<Props> = ({
  gradientColorOne,
  gradientColorTwo,
  gradientId,
}) => {
  return (
    <svg
      data-testid="topper"
      viewBox="0 0 1500 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="1500"
        height="100"
      >
        <path
          d="M0 37.5231C489.885 -12.5077 1010.11 -12.5077 1500 37.5231V101.523H0V37.5231Z"
          fill="pink"
        />
      </mask>
      {/* This gradient matches ChatterconfSectionGrid */}
      <linearGradient
        id={gradientId}
        gradientUnits="userSpaceOnUse"
        x1="-6.96%"
        y1="40.12%"
        x2="106.96%"
        y2="59.88%"
      >
        <stop offset=".066" stopColor={`${gradientColorOne}`} />
        <stop offset=".925" stopColor={`${gradientColorTwo}`} />
      </linearGradient>
      <g mask="url(#mask0)">
        <path
          d="M0 -0.121582H1500V185H0V-0.121582Z"
          fill={`url(#${gradientId})`}
        />
      </g>
    </svg>
  )
}

export default ChatterconfSectionTopper
