import React, { useState } from 'react'
import { Box, Grid, Flex } from '@chatterbug/aaron'
import Avatar from 'src/components/Avatar/Avatar'
import { Modal } from '@chatterbug/aaron/modal'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import ReactMarkdown from 'react-markdown'

export type IndividualBioCtaWithModalProps = {
  title: string
  imgUrl: string
  subtitle: string
  description: string
  secondarySection?: JSX.Element
  ariaLabel?: string
}

export const IndividualBioCtaWithModal: React.FC<IndividualBioCtaWithModalProps> = ({
  title,
  imgUrl,
  subtitle,
  description,
  secondarySection,
  ariaLabel,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <>
      <Button
        data-testid="cta"
        label="more info"
        aria-label={ariaLabel}
        href={null}
        onClick={() => setModalIsOpen(true)}
        variant={BUTTON_VARIANTS.neutral}
      />
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <Grid
          gridGap="5x"
          maxWidth="600px"
          textAlign="center"
          data-testid="modal-content"
        >
          <IndividualBio
            imgUrl={imgUrl}
            title={title}
            // TODO, add position at company to CMS data model; long bio byline
            subtitle={subtitle}
            description={description}
          />
          {secondarySection}
        </Grid>
      </Modal>
    </>
  )
}

export type Props = {
  imgUrl: string
  title: string
  subtitle: string
  description: string
  ctaSection?: JSX.Element | null
}
const IndividualBio: React.FC<Props> = ({
  imgUrl,
  title,
  subtitle,
  description,
  ctaSection,
}) => {
  return (
    <Flex
      flexDirection="column"
      flex="1"
      justifyContent="space-between"
      textAlign="center"
    >
      <Grid
        gridGap="3x"
        justifyItems="center"
        alignItems="center"
        mb={ctaSection && '3x'}
        gridTemplateRows="auto auto 1fr"
        flex="1"
      >
        <Avatar image={imgUrl} altText="" width={108} />
        <Grid gridGap="1x" justifyItems="center" textAlign="center">
          <Box as="p" variant="text.paragraph">
            {title}
          </Box>
          <Box as="p" variant="text.tinyTitle" color="gray60">
            {subtitle}
          </Box>
        </Grid>
        <Box variant="text.paragraph">
          <ReactMarkdown skipHtml>{description}</ReactMarkdown>
        </Box>
      </Grid>
      {ctaSection && (
        <Flex justifyContent="center" data-testid="ctaSection">
          {ctaSection}
        </Flex>
      )}
    </Flex>
  )
}

export default IndividualBio
