import React from 'react'
import { Box, Flex, Image } from '@chatterbug/aaron'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'
import heading from './assets/heading-text.svg'
import { ChatterconfFeaturedCompany } from 'src/types'
import { sortDesc } from 'src/lib/utils'

export type Props = {
  logoImage?: string
  date: string
  subtitle: string
  ctaLabel: string
  ctaLink: string
  featuredCompanies: ChatterconfFeaturedCompany[]
  onCTAClick: (eventLabel: string) => void
}

const ChatterconfHeroContent: React.FC<Props> = ({
  logoImage,
  date,
  subtitle,
  ctaLabel,
  ctaLink,
  featuredCompanies,
  onCTAClick,
}) => {
  return (
    <Flex color="white100" alignItems="center" flexDirection="column" my="6x">
      <Box as="p" color="yellow50">
        {date}
      </Box>
      <Box as="h1" mt="2x" fontWeight="600">
        <Box as="span" variant="a11y.hidden">
          Chatterconf 2022
        </Box>
        <Image src={logoImage || heading} alt="" />
      </Box>
      <Box as="p" mt="1x" color="yellow50">
        by chatterbug
      </Box>
      <Box
        as="p"
        mt="3x"
        maxWidth="700px"
        textAlign="center"
        variant="text.paragraph"
        color="white100"
        dangerouslySetInnerHTML={{ __html: subtitle }}
      />
      <Button
        mt="3x"
        label={ctaLabel}
        href={ctaLink}
        variant={BUTTON_VARIANTS.primary}
        onClick={() => onCTAClick('hero')}
      />
      <Flex width="100%" mt="4x" justifyContent="center" flexWrap="wrap">
        {sortDesc<ChatterconfFeaturedCompany>(featuredCompanies).map(
          (featuredCompany, index) => (
            <Box
              key={`featured-company-${index}`}
              as="img"
              src={featuredCompany.companyLogo}
              alt={featuredCompany.companyName}
              height={36}
              mr={index === featuredCompanies.length - 1 ? 0 : '3x'}
              opacity={0.5}
            />
          )
        )}
      </Flex>
    </Flex>
  )
}

export default ChatterconfHeroContent
