import React from 'react'
import { Grid, Box, Image } from '@chatterbug/aaron'
import Avatar from 'src/components/Avatar/Avatar'
export type Props = {
  imgUrl: string
  name: string
  logoUrl?: string
  logoName?: string
  title: string
  description: string
}

const Excerpt: React.FC<Props> = ({
  imgUrl,
  name,
  logoUrl,
  logoName,
  title,
  description,
}) => {
  return (
    <Grid
      gridGap="3x"
      data-testid="container"
      gridTemplateColumns={{ _: '1fr', tablet: '132px 1fr' }}
      alignItems="center"
    >
      <Grid gridGap="1x" textAlign="center" justifyContent="center">
        <Avatar image={imgUrl} altText="" />
        <Box as="p" variant="text.paragraph">
          {name}
        </Box>
        {logoUrl && <Image src={logoUrl} alt={logoName || ''} />}
      </Grid>
      <Grid gridGap="3x" textAlign={{ _: 'center', tablet: 'left' }}>
        <Box as="p" variant="text.h4">
          {title}
        </Box>
        <Box as="p" variant="text.paragraph">
          {description}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Excerpt
