import React from 'react'
import { Box } from '@chatterbug/aaron'

export type Props = {
  image: string
  altText: string
  maxWidth?: number
  width?:
    | {
        [key: string]: string | number
      }
    | string
    | number
}

const Avatar: React.FC<Props> = ({ image, width, maxWidth, altText }) => {
  return (
    <Box
      role="img"
      aria-label={altText}
      width={width}
      maxWidth={maxWidth}
      borderRadius="100%"
      flexShrink={0}
      sx={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top center',
        backgroundSize: 'cover',
      }}
    >
      {/* To make sure it's a circle */}
      <Box pt="100%" />
    </Box>
  )
}

export default Avatar
