import React, { useEffect, useState } from 'react'
import { Flex, Box } from '@chatterbug/aaron'
import Logo from 'src/components/Logo/Logo'
import Header from 'src/components/Header/Header'
import { NavigationItem } from 'src/types'
import Button, { BUTTON_VARIANTS } from 'src/components/Button/Button'

export type Props = {
  ctaLink: string
  canonicalPath: string
  showBreakoutsSection?: boolean
  onCTAClick: (eventLabel: string) => void
}

const ChatterconfHeader: React.FC<Props> = ({
  ctaLink,
  showBreakoutsSection,
  canonicalPath,
  onCTAClick,
}) => {
  const [items, setItems] = useState<NavigationItem[]>([])
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const realPath = window.location?.href
      const ITEMS = [
        {
          label: 'Speakers',
          name: 'speakers',
          path: realPath + '#speakers',
        },
        {
          label: 'Presentations',
          name: 'presentations',
          path: realPath + '#presentations',
        },
        {
          label: 'Schedule',
          name: 'schedule',
          path: ctaLink,
        },
      ]

      showBreakoutsSection &&
        ITEMS.splice(2, 0, {
          label: 'Breakout Rooms',
          name: 'breakout_rooms',
          path: realPath + '#breakouts',
        })

      setItems(ITEMS)
    }
  }, [ctaLink, showBreakoutsSection])

  return (
    <Header
      canonicalPath={canonicalPath}
      backgroundType="dark"
      items={items}
      hideLogin
      buttonSection={
        <Button
          label="register now"
          href={ctaLink}
          variant={BUTTON_VARIANTS.neutralOnDark}
          onClick={() => onCTAClick('header')}
        />
      }
      hideLocaleDropdown
      customLogo={
        <Flex alignItems="center">
          <Logo iconOnly isBackgroundDark />
          <Box variant="text.h5" color="white100" ml="1x">
            {'chatterconf'}
          </Box>
        </Flex>
      }
    />
  )
}

export default ChatterconfHeader
